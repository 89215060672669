// material
import { Box, Grid, Container, Typography, Button } from '@mui/material';
// components
import { ProductList } from '../components/_dashboard/products';
import PRODUCTS from '../_mocks_/products';
import Page from '../components/Page';
import LogoDisplay from '../components/_dashboard/blog/LogoDisplay';
import {
  AppTasks,
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppNewsUpdate,
  AppWeeklySales,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates
} from '../components/_dashboard/app';
// ----------------------------------------------------------------------

export default function DashboardApp() {
  return (
    <Page title="React Native AI">
      <Container maxWidth="xl">
        {/* <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi, Welcome back</Typography>
        </Box> */}
        <Grid container spacing={2}>
          {/* <Grid item xs={12} sm={12} md={12}>
            <ProductList limit={0} products={PRODUCTS} />
          </Grid> */}
          <Grid container spacing={12}>
            <Grid item xs={12} sm={4} md={4}>
              {/* <LogoDisplay
                key="title"
                post={
                { cover: '/img/reactnative-logo2.png',
                  title: 'React Native',
                  view: 'React Native',
                  comment: 'React Native',
                  share: 'React Native',
                  author: 'React Native',
                  createdAt: new Date()
                }
              }
                index={0}
              /> */}
              <Typography variant="h2" noWrap>
                React Native AI

              </Typography>
              <Typography variant="h2">
                <AppCurrentVisits />
                {/* <Button
                  fullWidth
                  href=""
                  target="_blank"
                  variant="contained"
                >
                  HIRE US
                </Button> */}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <AppCurrentSubject />

            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <AppOrderTimeline />

            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWeeklySales />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppItemOrders />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppBugReports />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <AppWebsiteVisits />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <AppCurrentVisits />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <AppConversionRates />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppTasks />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
